import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import facebooklogo from '../assets/images/icon/facebook.svg'
import linkedinlogo from '../assets/images/icon/linkedin.svg'
import slideone from '../assets/images/atakule.jpg'
import slidetwo from '../assets/images/kanyon.jpg'

import $ from 'jquery'

import LocalizedLink from '../LocalizedLink'

class IndexPage extends React.Component {
  componentDidMount() {
    $('.mb-nav-btn').click(function() {
      $('.mobile-menu').fadeIn(200)
    })
    $('.close').click(function() {
      $('.mobile-menu').fadeOut(200)
    })

    $('.mobile-menu ul li a').click(function() {
      $('.mobile-menu').fadeOut(200)
    })
  }

  render() {
    return (
      <div>
        <Layout locale={this.props.pageContext.locale}>
          <header className="header">
            <div className="row">
              <div className="col-3 logo">
                <a href="/">
                  <img
                    src={require('../assets/images/logo/kns.svg')}
                    alt="KNS"
                    title="KNS"
                  />
                </a>
              </div>
              <div className="col-9">
                <a href="#" id="nav-toggle" class="mb-nav-btn">
                  <span />
                </a>
                <nav className="menu">
                  <ul>
                    <li>
                      <LocalizedLink to="/aboutPage">
                        <FormattedMessage id="about" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/platformsPage">
                        <FormattedMessage id="platforms" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/iys">
                        <FormattedMessage id="İYS" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/supportPage">
                        <FormattedMessage id="supports" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/clientsPage">
                        <FormattedMessage id="clients" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/platforms/venuex">
                        <FormattedMessage id="Venuex" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/careerPage">
                        <FormattedMessage id="Career" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/contactPage">
                        <FormattedMessage id="Contact" />
                      </LocalizedLink>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </header>

          <div className="mobile-menu">
            <div className="close">
              <img src={require('../assets/images/icon/close-nav.svg')} />
            </div>

            <nav className="mb-nav">
              <ul>
                <li>
                  <LocalizedLink to="/aboutPage">
                    <FormattedMessage id="about" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/platformsPage">
                    <FormattedMessage id="platforms" />
                  </LocalizedLink>
                </li>
                <li>
                      <LocalizedLink to="/iys">
                        <FormattedMessage id="İYS" />
                      </LocalizedLink>
                    </li>
                <li>
                  <LocalizedLink to="/supportPage">
                    <FormattedMessage id="supports" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/clientsPage">
                    <FormattedMessage id="clients" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/platforms/venuex">
                    <FormattedMessage id="Venuex" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/careerPage">
                    <FormattedMessage id="Career" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/contactPage">
                    <FormattedMessage id="Contact" />
                  </LocalizedLink>
                </li>
              </ul>
            </nav>
          </div>

          <article className="home-social">
            <h2>
              <FormattedMessage id="follow" />
            </h2>
            <figure className="blue-line" />
            <a
              href="https://www.facebook.com/knsbilisim/"
              className="fb"
              target="_blank"
            >
              <img src={facebooklogo} />
            </a>
            <a
              href="https://www.linkedin.com/company/kns-information-technologies/"
              className="linkedin"
              target="_blank"
            >
              <img src={linkedinlogo} />
            </a>
          </article>

          <article className="home-social other-page-social">
            <h2>
              <FormattedMessage id="follow" />
            </h2>
            <figure className="blue-line" />
            <a
              href="https://www.facebook.com/knsbilisim/"
              className="fb"
              target="_blank"
            >
              <img src={facebooklogo} />
            </a>
            <a
              href="https://www.linkedin.com/company/kns-information-technologies/"
              className="linkedin"
              target="_blank"
            >
              <img src={linkedinlogo} />
            </a>
          </article>

          <div id="fullpage-wrapper">
            <div className="section section1" data-anchor="home">
              <section className="home">
                <div
                  id="carouselExampleIndicators"
                  className="carousel slide carousel-fade"
                  data-ride="carousel"
                  data-pause="false"
                >
                  <div className="carousel-inner" role="listbox">
                    <div
                      className="carousel-item slide-1"
                      style={{ backgroundImage: 'url(' + slideone + ')' }}
                    >
                      <div className="black-filter" />
                      <div className="carousel-caption d-none d-md-block">
                        {this.props.pageContext.locale == 'tr' && (
                          <>
                            <h3>
                              Create a remarkable visitor experience with the
                              touch of a <br />
                              sustainable digital marketing platform.
                            </h3>
                          </>
                        )}
                        {this.props.pageContext.locale == 'en' && (
                          <>
                            <h3>
                              Create a remarkable visitor experience with the
                              touch of a <br />
                              sustainable digital marketing platform.
                            </h3>
                          </>
                        )}
                      </div>
                    </div>
                    <div
                      className="carousel-item slide-2"
                      style={{ backgroundImage: 'url(' + slidetwo + ')' }}
                    >
                      <div className="black-filter" />
                      <div className="carousel-caption d-none d-md-block">
                        {this.props.pageContext.locale == 'tr' && (
                          <>
                            <h3>
                              Easily create a visionary, reliable and engaging
                              digital marketing platform
                              <br />
                              with the help of a dedicated expert team.
                            </h3>
                          </>
                        )}
                        {this.props.pageContext.locale == 'en' && (
                          <>
                            <h3>
                              Easily create a visionary, reliable and engaging
                              digital marketing platform
                              <br />
                              with the help of a dedicated expert team.
                            </h3>
                          </>
                        )}
                      </div>
                    </div>

                    {/* <div className="carousel-item slide-3">
                      <div className="black-filter" />
                      <div className="carousel-caption d-none d-md-block">
                        {this.props.pageContext.locale == 'tr' && (
                          <>
                            <h3>KNS; Simple, fast and effective solutions </h3>
                          </>
                        )}
                        {this.props.pageContext.locale == 'en' && (
                          <>
                            <h3>KNS; Simple, fast and effective solutions </h3>
                          </>
                        )}
                      </div>
                    </div> */}

                    <div className="carousel-item slide-4 active">
                      <div className="black-filter" />
                      <div className="carousel-caption d-none d-md-block">
                        {this.props.pageContext.locale == 'tr' && (
                          <>
                            <h3>
                              Say hello to the new generation of marketing with
                              KNS.
                            </h3>
                          </>
                        )}
                        {this.props.pageContext.locale == 'en' && (
                          <>
                            <h3>
                              Say hello to the new generation of marketing with
                              KNS.
                            </h3>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <article className="properties">
                  <aside className="numbers">
                    <h1>8+</h1>
                    <h4>
                      <FormattedMessage id="years" />
                    </h4>
                  </aside>
                  <aside className="numbers">
                    <h1>7</h1>
                    <h4>
                      <FormattedMessage id="countries" />
                    </h4>
                  </aside>
                  <aside className="numbers">
                    <h1>150+</h1>
                    <h4>
                      <FormattedMessage id="customers" />
                    </h4>
                  </aside>
                </article>
              </section>
            </div>
          </div>
        </Layout>
      </div>
    )
  }
}

IndexPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}
export default IndexPage
